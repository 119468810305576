import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step api token create`}</strong>{` -- create a new token`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step api token create <team-id> <crt-file> <key-file>
[--api-url=<url>]

`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca api token create`}</strong>{` creates a new token for connecting to the Smallstep API.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`team-id`}</inlineCode>{`
UUID of the team the API token will be issued for. This is available in the Smallstep dashboard.`}</p>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
File to read the certificate (PEM format). This certificate must be signed by a trusted root configured in the Smallstep dashboard.`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
File to read the private key (PEM format).`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--api-url`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
URL where the Smallstep API can be found`}</p>
    <h2>{`Examples`}</h2>
    <p>{`$ step api token create ff98be70-7cc3-4df5-a5db-37f5d3c96e23 internal.crt internal.key`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      